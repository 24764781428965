/**
 * Created by Slava on 08.09.2023
 */

var StartTutorialScenario = {
    name: "yatzy_basics_tutorial",
    priority: 10,

    context: Object.assign({}, TutorialContext, {
        getHighlightElements: function (data) {
            var scene = cleverapps.scenes.getRunningScene();

            if (!this.highlights) {
                this.highlights = [scene.rollButton, scene.dicesView, scene.movingNode];
            }

            if (data.column1Highlight === true) {
                this.highlights.push(scene.yatzyField.column1);
            }

            if (data.column2Highlight === true) {
                this.highlights.push(scene.yatzyField);
            }

            if (data.slot !== undefined) {
                this.highlights.push(scene.resultsBar);
            }

            this.highlights = cleverapps.unique(this.highlights);
            return this.highlights;
        }
    }),

    steps: [
        {
            roll: [2, 5, 3, 5, 1],
            noImportantNodes: true,
            noAutoRoll: true,
            force: {
                text: "tutorial_1_1"
            }
        },
        {
            dice: 1,
            force: {
                text: "tutorial_1_2"
            }
        },
        {
            dice: 3,
            force: {
                text: "tutorial_1_2"
            }
        },
        {
            roll: [1, 0, 2, 0, 5],
            force: {
                text: "tutorial_1_3"
            }
        },
        {
            dice: 4,
            force: {
                text: "tutorial_1_4"
            }
        },
        {
            roll: [5, 0, 1, 0, 0],
            force: {
                text: "tutorial_1_5"
            }
        },
        {
            slot: Slot.FIVES,
            column1Highlight: true,
            force: {
                text: "tutorial_1_6"
            }
        },
        {
            roll: [6, 2, 4, 5, 6],
            force: {
                text: "",
                pointer: false
            }
        },
        {
            dice: 0,
            force: {
                text: ""
            }
        },
        {
            dice: 4,
            force: {
                text: ""
            }
        },
        {
            roll: [6, 6, 3, 1, 6],
            force: {
                text: ""
            }
        },
        {
            dice: 1,
            force: {
                text: ""
            }
        },
        {
            roll: [6, 6, 2, 6, 6],
            force: {
                text: ""
            }
        },
        {
            slot: Slot.SIXES,
            force: {
                text: "tutorial_5_2",
                position: [
                    { x: { align: "left" }, y: { align: "center", dy: 25 } },
                    { x: { align: "left" }, y: { align: "center", dy: -50 } },
                    { x: { align: "left" }, y: { align: "center", dy: -150 } }
                ]
            }
        },
        {
            roll: [3, 3, 4, 5, 1],
            force: {
                text: "",
                pointer: false
            }
        },
        {
            dice: 0,
            force: {
                text: ""
            }
        },
        {
            dice: 1,
            force: {
                text: ""
            }
        },
        {
            roll: [3, 3, 5, 2, 1],
            force: {
                text: ""
            }
        },
        {
            roll: [3, 3, 1, 3, 5],
            force: {
                text: ""
            }
        },
        {
            slot: Slot.THREES,
            force: {
                text: "tutorial_6_1",
                position: {
                    x: { align: "left" },
                    y: { align: "center", dy: 300 }
                }
            }
        },
        {
            roll: [1, 2, 3, 1, 6],
            force: {
                text: "",
                pointer: false
            }
        },
        {
            dice: 0,
            column2Highlight: true,
            force: {
                text: "tutorial_4_1"
            }
        },
        {
            dice: 1,
            force: {
                text: ""
            }
        },
        {
            dice: 2,
            force: {
                text: ""
            }
        },
        {
            roll: [1, 2, 3, 4, 2],
            force: {
                text: ""
            }
        },
        {
            dice: 3,
            force: {
                text: "tutorial_4_2"
            }
        },
        {
            roll: [1, 2, 3, 4, 5],
            force: {
                text: ""
            }
        },
        {
            slot: Slot.LARGE_STRAIGHT,
            force: {
                text: "tutorial_4_3"
            }
        }
    ]
};
