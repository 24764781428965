/**
 * Created by Mikhail Menshenin on 2/29/24
 */

var MedalLibraryTabView = cleverapps.Layout.extend({
    ctor: function (game) {
        var styles = this.styles = cleverapps.styles.MedalLibraryTabView;

        var currentLevel = game.getCurrentLevel();
        var maxLevel = MedalCollection.MAX_LEVEL;

        var unlockedLabelText = cleverapps.UI.generateOnlyText("MedalCollectionWindow.UnlockedText", styles.unlocked.label.font);
        var unlockedSpaceNode = new cc.Node();
        unlockedSpaceNode.setContentSize(styles.unlocked.margin, unlockedLabelText.height);
        var unlockedLevelText = this.unlockedLevelText = cleverapps.UI.generateOnlyText(currentLevel, styles.unlocked.number.font);
        var maxLevelText = cleverapps.UI.generateOnlyText("/%{maxLevel}", styles.unlocked.label.font, { maxLevel: maxLevel });
        var unlockedText = this.unlockedText = new cleverapps.Layout([unlockedLabelText, unlockedSpaceNode, unlockedLevelText, maxLevelText], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: 0
        });

        var descText = cleverapps.UI.generateOnlyText("MedalCollectionWindow.Description", styles.description.font);
        descText.setOpacity(128);

        var cards = [];

        for (var level = 1; level <= maxLevel; level++) {
            var card = new MedalCardView(game, level, currentLevel, level % MedalLibraryTabView.LIBRARY_COLUMN_COUNT);
            cards.push(card);
        }

        var grid = new cleverapps.GridLayout(cards, {
            columns: MedalLibraryTabView.LIBRARY_COLUMN_COUNT,
            align: { x: cleverapps.GridLayout.ALIGN_START },
            margin: styles.grid.margin,
            padding: styles.grid.padding
        });

        this._super([unlockedText, descText, grid.height > styles.scroll.height ? this.createScroll(grid) : grid], {
            direction: cleverapps.UI.VERTICAL,
            padding: styles.padding,
            margin: 0
        });

        this.setAnchorPoint(0.5, 0.5);
        this.setPositionRound(styles);

        game.on("unlocked", this.onUnlocked.bind(this), this);
    },

    createScroll: function (grid) {
        var scroll = new cleverapps.UI.ScrollView(grid, {
            scrollBarEnabled: false,
            direction: cleverapps.UI.ScrollView.DIR_VERTICAL
        });
        scroll.setContentSize(grid.width, this.styles.scroll.height);
        scroll.scrollToDefault();

        return scroll;
    },

    onUnlocked: function (unlockedLevel) {
        this.unlockedLevelText.setString(unlockedLevel);
        this.unlockedText.reshape();
    }
});

MedalLibraryTabView.LIBRARY_COLUMN_COUNT = 4;

cleverapps.styles.MedalLibraryTabView = {
    x: { align: "center" },
    y: { align: "center" },

    padding: { top: 50 },

    grid: {
        margin: {
            x: 34,
            y: 25
        },
        padding: { top: 20, bottom: 40 },

        row: {
            margin: 2,
            x: { align: "left" },
            y: { align: "center" }
        }
    },

    unlocked: {
        margin: 20,

        label: {
            font: {
                size: 50,
                color: new cc.Color(171, 79, 68, 255),
                shadow: {
                    color: new cc.Color(255, 255, 220, 205),
                    direction: cc.size(0, 2),
                    blur: 0
                }
            }
        },

        number: {
            font: {
                size: 50,
                color: new cc.Color(187, 141, 241, 255)
            }
        }
    },

    description: {
        font: {
            size: 28,
            color: new cc.Color(171, 79, 68, 128),
            shadow: {
                color: new cc.Color(255, 255, 220, 205),
                direction: cc.size(0, 2),
                blur: 0
            }
        }
    },

    scroll: {
        height: 1000
    }
};