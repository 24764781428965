/**
 * Created by olga on 12.03.2024
 */

var MarkView = cc.Node.extend({
    ctor: function (mark) {
        this.mark = mark;
        this._super();

        var markImage = this.image = this.getMarkImage(mark);
        markImage.setPositionRound({ x: { align: "center" }, y: { align: "center" } });
        this.setContentSize2(markImage.getContentSize());
        this.addChild(markImage);

        this.setLocalZOrder(10);
        this.setPositionRound(cleverapps.styles.MarkView);

        mark.on("collect", this.collect.bind(this), this);
    },

    collect: function (targetName, callback) {
        var sixShine = new cleverapps.Spine(bundles.yatzy.jsons.small_six_shine_json);
        sixShine.setPositionRound(cleverapps.styles.MarkView.icon);
        sixShine.setAnimation(0, "animation", false);
        this.addChild(sixShine);
        this.image.removeFromParent();

        var flyIcon = sixShine;
        var target = cleverapps.aims.getTarget(targetName);

        flyIcon.replaceParentSamePlace(cleverapps.scenes.getMovingNode());
        flyIcon._setGlobalZOrder(true);
        flyIcon.setLocalZOrder(10);

        this.runAction(
            new cc.Sequence(
                new cc.ScaleTo(0.3, 0.3),
                new cc.RemoveSelf()
            )
        );

        flyIcon.runAction(
            new cc.Sequence(
                new cc.Spawn(
                    AnimationsLibrary.animateCollect(flyIcon, target, {
                        duration: 0.5
                    }),
                    new cc.Sequence(
                        new cc.CallFunc(function () {
                            cleverapps.audio.playSound(bundles.game.urls.yatzy_tournament);
                        })
                    )
                ),
                new cc.RemoveSelf(),
                new cc.DelayTime(),
                new cc.CallFunc(callback || function () {})
            )
        );
    },

    getMarkImage: function () {
        var icon = new cc.Sprite(this.mark.getImage());
        icon.setPositionRound(cleverapps.styles.MarkView.icon);
        return icon;
    }
});

cleverapps.styles.MarkView = {
    x: { align: "left", dx: -24 },
    y: { align: "bottom", dy: -24 },
    icon: {
        x: { align: "center", dx: 1 },
        y: { align: "center", dy: 2 }
    }
};
