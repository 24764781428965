/**
 * Created by mac on 8/21/23
 */

var DicesView = cc.Node.extend({
    avoidNode: "DicesView",
    ctor: function (dices) {
        this.dices = dices;

        this._super();

        var styles = cleverapps.styles.DicesView;

        this.setAnchorPoint(0.5, 0.5);

        this.dicesBackground = cleverapps.UI.createScale9Sprite(bundles.bg.frames.dices_bg);
        this.dicesBackground.setAnchorPoint(0, 0);

        this.addChild(this.dicesBackground);

        var diceViews = this.dices.map(function (dice) {
            return new DiceView(dice);
        });
        var dicesLayout = new cleverapps.Layout(diceViews, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin.x
        });
        dicesLayout.setPositionRound(styles.layout);
        this.addChild(dicesLayout);

        this.textDescription = cleverapps.UI.generateOnlyText("dicesDescription", cleverapps.styles.FONTS.GRAY_TEXT);
        this.textDescription.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        this.textDescription.setPositionRound(styles.text.position);
        this.dicesBackground.addChild(this.textDescription);
        this.hideDescription();

        this.updateSize();

        this.visible = false;

        Game.currentGame.on("play", this.animatePlay.bind(this), this);
        Game.currentGame.on("showDices", this.animateShow.bind(this), this);
        Game.currentGame.on("hideDices", this.animateHide.bind(this), this);
        Game.currentGame.on("showDicesDescription", this.showDescription.bind(this), this);
        Game.currentGame.on("highlightDices", this.animateHighlight.bind(this));
    },

    animateShow: function (silent) {
        this.setVisible(true);

        if (silent) {
            return;
        }

        this.dices.forEach(function (dice) {
            dice.trigger("hide", true);
        });

        this.dicesBackground.setOpacity(0);

        var styles = cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? cleverapps.styles.DicesView.showAnimation.vertical : cleverapps.styles.DicesView.showAnimation.horizontal;
        this.dicesBackground.runAction(
            new cc.Sequence(
                new cc.MoveBy(0, styles.dx, styles.dy),
                new cc.Spawn(
                    new cc.FadeIn(0.3),
                    new cc.MoveTo(0.3, this.dicesBackground.x, this.dicesBackground.y)
                )
            )
        );
    },

    animateHide: function () {
        var styles = cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? cleverapps.styles.DicesView.showAnimation.vertical : cleverapps.styles.DicesView.showAnimation.horizontal;

        this.dices.forEach(function (dice) {
            dice.hide();
        });

        this.dicesBackground.runAction(
            new cc.Sequence(
                new cc.DelayTime(0.3),
                new cc.Spawn(
                    new cc.MoveBy(0.2, styles.dx, styles.dy),
                    new cc.FadeOut(0.2),
                    new cc.CallFunc(function () {
                        this.textDescription.runAction(new cc.FadeOut(0.2));
                    }.bind(this))
                )
            )
        );
    },

    showDescription: function () {
        this.textDescription.setVisible(true);
    },

    hideDescription: function () {
        this.textDescription.setVisible(false);
    },

    animatePlay: function (selectedSlot, callback) {
        this.dices.forEach(function (dice) {
            this.runAction(new cc.Sequence(
                new cc.DelayTime(dice.id * 0.1),
                new cc.CallFunc(function () {
                    dice.flight(selectedSlot, callback);
                })
            ));
        }.bind(this));

        if (selectedSlot.isShineTarget()) {
            cleverapps.timeouts.setTimeout(function () {
                cleverapps.audio.playSound(bundles.game.urls.collect);
            }, 666);
        } else {
            cleverapps.audio.playSound(bundles.game.urls.collect);
        }
    },

    animateHighlight: function (dices, cb) {
        this.dices.filter(function (dice) {
            return dices.indexOf(dice.id) !== -1;
        }).forEach(function (dice, index) {
            this.runAction(new cc.Sequence(
                new cc.DelayTime(index * 0.1),
                new cc.CallFunc(function () {
                    dice.highlight(cb);
                })
            ));
        }.bind(this));
    },

    updateSize: function () {
        var styles = cleverapps.styles.DicesView.size;
        var size = (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) ? styles.vertical : styles.horizontal;
        this.setContentSize2(size.width, size.height);
        this.dicesBackground.setContentSize2(this.width, this.height);
        this.textDescription.setDimensions(this.width, 0);
    }
});

cleverapps.styles.DicesView = {
    margin: {
        x: 32
    },

    text: {
        position: [{
            x: { align: "center" },
            y: { align: "top", dy: -10 }
        }, {
            x: { align: "center" },
            y: { align: "top", dy: -90 }
        }, {
            x: { align: "center" },
            y: { align: "top", dy: -90 }
        }]
    },

    showAnimation: {
        horizontal: {
            dx: 100,
            dy: 0
        },
        vertical: {
            dx: -100,
            dy: 0
        }
    },

    layout: {
        x: { align: "center" },
        y: { align: "center" }
    },

    size: {
        horizontal: {
            width: 900,
            height: 540
        },
        vertical: {
            width: 900,
            height: 260
        }
    }
};