/**
 * Created by olga on 06.08.2024
 */

var Mark = function (type) {
    cleverapps.EventEmitter.call(this);

    this.type = type;
};

Mark.prototype = Object.create(cleverapps.EventEmitter.prototype);
Mark.prototype.constructor = Mark;

Mark.prototype.collect = function () {
    var target = this.getTargetName();

    if (this.type === "letter") {
        Game.currentGame.addClover(Mission.TYPE_LETTER, 1, true);

        this.trigger("collect", target, function () {
            Game.currentGame.trigger("rewardClover");
        });
    }
};

Mark.prototype.getType = function () {
    return this.type;
};

Mark.prototype.getImage = function () {
    if (this.type === "letter") {
        return bundles.yatzy.frames.letter_mark_png;
    }
};

Mark.prototype.getTargetName = function () {
    if (this.type === "letter") {
        return "mission_reward" + Mission.TYPE_LETTER;
    }
};
