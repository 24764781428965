/**
 * Created by slava on 06.07.2023
 */

var DiceView = cc.Node.extend({
    ctor: function (dice) {
        this._super();
        this.dice = dice;
        var style = cleverapps.styles.Dice;

        var view = this.view = new cleverapps.Spine(bundles.dices.jsons.roll_animation);
        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize(view.getContentSize());
        view.setPosition(this.width / 2, this.height / 2);
        this.addChild(view);

        var choose = this.choose = cleverapps.UI.createScale9Sprite(bundles.slots.frames.choose);
        choose.setContentSize2(style.choose);
        choose.setPositionRound(style.choose.position);
        this.addChild(choose, -1);
        choose.setVisible(false);

        cleverapps.UI.onClick(this, this.createListener(dice.onClick.bind(dice)));

        this.setVisible(false);

        if (this.dice.score) {
            this.animateRoll(this.dice.score);
        }

        if (this.dice.chosen) {
            this.animateChoose(this.dice.chosen);
        }

        this.dice.on("changeChosen", this.animateChoose.bind(this), this);
        this.dice.on("roll", this.animateRoll.bind(this), this);
        this.dice.on("hide", this.animateHide.bind(this), this);
        this.dice.on("flight", this.animateFlight.bind(this), this);
        this.dice.on("highlight", this.animateHighlight.bind(this), this);

        cleverapps.aims.registerTarget("dice" + this.dice.id, this);
    },

    animateChoose: function (chosen) {
        this.choose.setVisible(chosen);

        cleverapps.audio.playSound(bundles.game.urls.pick);
    },

    animateRoll: function (previousScore, cb) {
        var isInitialRoll = !this.visible;

        this.removeMark();

        this.runAction(
            cc.sequence(
                cc.delayTime(isInitialRoll ? this.dice.id * 0.1 : 0),
                cc.callFunc(function () {
                    this.setVisible(true);
                    this.view.setSkin(previousScore + " to " + this.dice.score);

                    this.view.setAnimation(0, isInitialRoll ? "finish" : "start", false);

                    var after = 1;
                    if (!isInitialRoll) {
                        this.view.addAnimation(0, "finish", false);
                        after++;
                    }

                    this.view.setCompleteListener(function () {
                        if (--after === 0) {
                            this.addMark();
                            this.view.setCompleteListener();

                            if (cb) {
                                cb();
                            }
                        }
                    }.bind(this));
                }.bind(this))
            )
        );
    },

    addMark: function () {
        if (this.dice.mark) {
            this.markView = new MarkView(this.dice.mark);
            this.addChild(this.markView);
        }
    },

    removeMark: function () {
        if (this.markView) {
            this.markView.removeFromParent(true);
            this.markView = undefined;
        }
    },

    animateHide: function (silent) {
        var index = this.dice.id;

        if (!this.visible) {
            return;
        }

        if (silent) {
            this.setVisible(false);
            return;
        }

        this.runAction(
            new cc.Sequence(
                new cc.DelayTime((4 - index) * 0.05),
                cc.spawn(
                    cc.sequence(
                        cc.scaleTo(0.05, 1.2),
                        cc.scaleTo(0.15, 0.3)
                    )
                ),
                new cc.CallFunc(function () {
                    this.setVisible(false);
                }.bind(this))
            )
        );
    },

    animateFlight: function (slot, callback) {
        var index = this.dice.id;
        var flightDice = this.getFlightDice();
        var target = cleverapps.aims.getTarget("slot" + slot.id);

        flightDice.replaceParentSamePlace(cleverapps.scenes.getMovingNode());
        flightDice._setGlobalZOrder(true);

        var pos = flightDice.parent.convertToNodeSpace(target.parent.convertToWorldSpace(target.getPosition()));
        var points = [flightDice.getPosition(), cc.p((flightDice.x + pos.x) / 2, (flightDice.y + pos.y) / 2), pos];

        var actions = [
            new cc.CallFunc(function () {
                this.setVisible(false);
            }.bind(this)),
            new cc.SpineAction(flightDice, "animations", false),
            new cc.BezierTo(0.5, points).easing(cc.easeIn(1)),
            new cc.CallFunc(function () {
                if (index === 0) {
                    slot.collect();
                }

                if (index === 4) {
                    callback();
                }
            }),
            new cc.RemoveSelf()
        ];

        flightDice.runAction(new cc.Sequence(actions));
    },

    animateHighlight: function (cb) {
        var score = this.dice.score;
        this.view.setVisible(false);
        var highlight = new cleverapps.Spine(bundles.dices.jsons.roll_animation);
        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize(highlight.getContentSize());
        highlight.setPosition(this.width / 2, this.height / 2);
        this.addChild(highlight);
        highlight.setSkin("highlight" + score);
        highlight.setAnimation(0, "highlight_dice", false);
        highlight.setCompleteListenerRemove(function () {
            this.view.setVisible(true);
            cb && cb();
        }.bind(this));
    },

    getFlightDice: function () {
        var flightDice = new cleverapps.Spine(bundles.dices.jsons.flight_animation);
        flightDice.setSkin("cube_" + this.dice.score);
        flightDice.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(flightDice);

        return flightDice;
    },

    wiggleOn: function () {
        this.view.runAction(
            cc.repeatForever(
                cc.sequence(
                    cc.rotateTo(0.2, -5).easing(cc.easeIn(1)),
                    cc.rotateTo(0.2, 5).easing(cc.easeOut(1))
                )
            )
        );
    },

    wiggleOff: function () {
        this.view.stopAllActions();
        this.view.setRotation(0);
    }
});

cleverapps.styles.Dice = {
    choose: {
        width: 165,
        height: 165,
        position: {
            x: { align: "center" },
            y: { align: "center" }
        }
    }
};