/*
 * Created by Mikhail Menshenin on 09.04.2024
 */

var MedalCardView = cc.Scale9Sprite.extend({
    ctor: function (game, level, maxUnlockedLevel, withArrow) {
        this._super(bundles.medalcollection.frames.medal_collection_bg_png);

        var styles = cleverapps.styles.MedalCardView;

        var levelText = cleverapps.UI.generateOnlyText(level, cleverapps.styles.FONTS.MEDAL_CARD_LEVEL_TEXT);
        levelText.setPositionRound(styles.level);
        this.addChild(levelText);

        var header = this.header = this.createHeader();
        header.setPositionRound(styles.header);
        this.addChild(header);

        var icon = this.icon = new cc.Sprite(bundles.medalcollection.frames.question_mark_png);
        icon.setPositionRound(styles.icon);
        this.addChild(icon);

        if (withArrow) {
            var arrow = new cc.Sprite(bundles.medalcollection.frames.next_medal_arrow_png);
            arrow.setPositionRound(styles.arrow);
            this.addChild(arrow);
        }

        this.updateCurrentLevel(level, maxUnlockedLevel);
        game.on("unlocked", this.updateCurrentLevel.bind(this, level), this);

        if (cleverapps.config.debugMode) {
            cleverapps.UI.onClick(icon, function () {
                game.addMore(1);
                game.spawn(level);
            }, {
                interactiveScale: false
            });
        }

        this.setContentSize(styles);
    },

    createHeader: function () {
        var styles = cleverapps.styles.MedalCardView.header;
        var header = cleverapps.UI.createScale9Sprite(bundles.medalcollection.frames.header_locked_png);
        header.setContentSize(styles);

        var headerText = this.headerText = cleverapps.UI.generateOnlyText("MedalCollection.Locked", cleverapps.styles.FONTS.MEDAL_CARD_HEADER_TEXT);
        headerText.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        headerText.setWrapWidth(header.width - styles.text.wrap);
        headerText.setPositionRound(styles.text);
        header.addChild(headerText);

        return header;
    },

    updateCurrentLevel: function (level, maxUnlockedLevel) {
        var styles = cleverapps.styles.MedalCardView;

        var locked = level > maxUnlockedLevel;
        var firstLocked = level === maxUnlockedLevel + 1;
        var restLocked = locked && !firstLocked;

        this.headerText.setOpacity(locked ? styles.locked.opacity : styles.unlocked.opacity);
        this.icon.setOpacity(firstLocked ? styles.locked.opacity : styles.unlocked.opacity);
        this.icon.setSpriteFrame(restLocked ? bundles.medalcollection.frames.question_mark_png : bundles.medalcollection.frames["medal_" + level]);
        this.header.setSpriteFrame(restLocked ? bundles.medalcollection.frames.header_locked_png : bundles.medalcollection.frames.header_opened_png);
        this.headerText.setString(restLocked ? "MedalCollection.Locked" : "MedalCollection.MedalName" + level);
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    MEDAL_CARD_HEADER_TEXT: {
        size: 20,
        color: cleverapps.styles.COLORS.WHITE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_INVERSED_SHADOW
    },

    MEDAL_CARD_LEVEL_TEXT: {
        size: 28,
        color: cleverapps.styles.COLORS.WHITE
    }
});

cleverapps.styles.MedalCardView = {
    width: 161,
    height: 230,

    icon: {
        x: { align: "center" },
        y: { align: "center", dy: -22 }
    },

    header: {
        x: { align: "center" },
        y: { align: "top" },
        width: 160,
        height: 63,

        text: {
            x: { align: "center" },
            y: { align: "center" },
            wrap: 16
        }
    },

    unlocked: {
        opacity: 255
    },

    locked: {
        opacity: 100
    },

    arrow: {
        x: { align: "right", dx: 32 },
        y: { align: "center", dy: -22 }
    },

    level: {
        x: { align: "right", dx: -10 },
        y: { align: "bottom", dy: 6 }
    }
};
