/**
 * Created by mac on 7/4/23
 */

var Slot = function (id, save) {
    cleverapps.EventEmitter.call(this);

    this.id = id;

    this.score = save && save.score || 0;
    this.used = save && save.used || false;
    this.opponentScore = save && save.opponentScore || 0;
    this.opponentUsed = save && save.opponentUsed || false;
};

Slot.prototype = Object.create(cleverapps.EventEmitter.prototype);
Slot.prototype.constructor = Slot;

Slot.prototype.getInfo = function () {
    return {
        score: this.score,
        used: this.used
    };
};

Slot.prototype.onClick = function () {
    if (this.used || Game.currentGame.counter.isActive()) {
        return;
    }

    Game.currentGame.play(this);
};

Slot.prototype.select = function () {
    if (!this.used) {
        this.used = true;
        this.trigger("selected");
    }
};

Slot.prototype.collect = function () {
    this.trigger("collect");
};

Slot.prototype.confirm = function () {
    this.trigger("confirm");
};

Slot.prototype.setScore = function (score) {
    this.score = score;
    this.trigger("scored");
};

Slot.prototype.setOpponentScore = function (score) {
    this.opponentScore = score;
    this.opponentUsed = true;
    this.trigger("scored");
};

Slot.prototype.calcScore = function (yatzyPlayed) {
    var dices = Game.currentGame.dices;
    var totalSum = 0;
    var allDices = [];

    var i;
    var count;

    for (i = 1; i <= 6; i++) {
        allDices[i] = 0;
    }

    for (i = 0; i <= 4; i++) {
        allDices[dices[i].score]++;
        totalSum += (dices[i].score || 0);
    }

    if (yatzyPlayed) {
        for (i = 1; i <= 6; i++) {
            if (allDices[i] === 5) {
                return Slot.YATZY_SCORE;
            }
        }
    }

    if ([Slot.ONES, Slot.TWOS, Slot.THREES, Slot.FOURS, Slot.FIVES, Slot.SIXES].indexOf(this.id) !== -1) {
        return (this.id + 1) * allDices[this.id + 1];
    }

    switch (this.id) {
        case Slot.THREE_OF_A_KIND:
            for (i = 1; i <= 6; i++) {
                if (allDices[i] >= 3) {
                    return totalSum;
                }
            }
            return 0;
        case Slot.FOUR_OF_A_KIND:
            for (i = 1; i <= 6; i++) {
                if (allDices[i] >= 4) {
                    return totalSum;
                }
            }
            return 0;
        case Slot.SMALL_STRAIGHT:
            count = 0;
            for (i = 1; i <= 6; i++) {
                if (allDices[i] > 0) {
                    count++;
                    if (count === 4) {
                        return Slot.SMALL_STRAIGHT_SCORE;
                    }
                } else {
                    count = 0;
                }
            }
            return 0;
        case Slot.LARGE_STRAIGHT:
            count = 0;
            for (i = 1; i <= 6; i++) {
                if (allDices[i] > 0) {
                    count++;
                    if (count === 5) {
                        return Slot.LARGE_STRAIGHT_SCORE;
                    }
                } else {
                    count = 0;
                }
            }
            return 0;
        case Slot.FULL_HOUSE:
            var hasPair = false;
            var hasThree = false;
            for (i = 1; i <= 6; i++) {
                if (allDices[i] === 2) {
                    hasPair = true;
                }
                if (allDices[i] === 3) {
                    hasThree = true;
                }
            }
            if (hasPair && hasThree) {
                return Slot.FULL_HOUSE_SCORE;
            }
            return 0;
        case Slot.YATZY:
            for (i = 1; i <= 6; i++) {
                if (allDices[i] === 5) {
                    return Slot.YATZY_SCORE;
                }
            }
            return 0;
        case Slot.CHANCE:
            return totalSum;
    }
    return 0;
};

Slot.prototype.isShineTarget = function () {
    return this.id > Slot.SIXES && this.id < Slot.CHANCE;
};

Slot.prototype.highlight = function (cb) {
    this.trigger("highlight", cb);
};

Slot.ONES = 0;
Slot.TWOS = 1;
Slot.THREES = 2;
Slot.FOURS = 3;
Slot.FIVES = 4;
Slot.SIXES = 5;
Slot.THREE_OF_A_KIND = 6;
Slot.FOUR_OF_A_KIND = 7;
Slot.FULL_HOUSE = 8;
Slot.SMALL_STRAIGHT = 9;
Slot.LARGE_STRAIGHT = 10;
Slot.YATZY = 11;
Slot.CHANCE = 12;

Slot.SMALL_STRAIGHT_SCORE = 30;
Slot.LARGE_STRAIGHT_SCORE = 40;
Slot.FULL_HOUSE_SCORE = 25;
Slot.YATZY_SCORE = 50;